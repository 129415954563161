import React from "react";
import DashboardNavbar from "../../components/DashboardNavbar";
import BuyerDashboardNavbar from "../../components/BuyerDashboardNavbar";

const BuyerHome = () => {
    return (
        <>
            <BuyerDashboardNavbar />
        </>
    );
};

export default BuyerHome;
